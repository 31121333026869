.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: transparent;
}

.modal {
  z-index: 10;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0.375rem;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: #fef0f0;
  color: #f56c6c;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 1px #f56c6c;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  align-items: center;
  display: flex;

  &-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    &-icon {
      font-size: 2.5rem;
    }
  }

  &-footer {
    display: flex;
    gap: 0.5rem;

    &-confirm {
      font-size: 1rem;
      color: white;
      background-color: #f56c6c;
      border: 1px solid #f56c6c;
      padding: 0.25rem 0.375rem;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: #f78989;
        border-color: #f78989;
      }

      &:active {
        background: #dd6161;
        border-color: #dd6161;
      }

      &-icon {
        margin-right: 0.25rem;
      }
    }

    &-cancel {
      font-size: 1rem;
      color: #f56c6c;
      background: #fff;
      border: 1px solid #fbc4c4;
      padding: 0.25rem 0.375rem;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: #f78989;
        border-color: #f78989;
        color: #fff;
      }

      &:active {
        background: #dd6161;
        border-color: #dd6161;
      }

      &-icon {
        margin-right: 0.25rem;
      }
    }
  }
}

@media only screen and (max-device-width: 640px) {
  .modal {
    width: 80vw;
  }
}