.image-list-wrapper {
  padding: 0.75rem 5%;
  overflow: auto;
  margin-bottom: 2%;

  .image-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;

    .image-list {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      color: black;
      font-size: 1rem;
      align-items: center;
      justify-content: center;
      max-width: 70vw;
      min-width: 80vmin;

      .image-item {
        text-align: left;
        transition: all 0.5s;
        margin: 0.25rem;
        position: relative;

        .image-preview {
          height: 10rem;
          width: 10rem;
        }

        .image-preview.uploaded {
          cursor: pointer;
        }

        .remove-button {
          position: absolute;
          right: -7px;
          top: -7px;
          background-color: white;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 50%);
          cursor: pointer;
          font-size: 14px;
          transition: color 0.2s;

          &-xmark {
            transform: translate(0%, -1%);
          }
        }

        .remove-button:hover {
          box-shadow: 1px 1px 4px 0 rgba(89, 96, 160, 1);
          color: rgba(89, 96, 160, 1);
        }
      }
    }

    .image-upload-error {
      font-size: 1rem;
      color: white;
      background-color: #f56c6c;
      padding: 0.5rem 0.75rem;
      border-radius: 4px;
      text-align: center;
    }
  }
}