.select-file-button-wrapper {
    padding-top: .2rem;
    position: relative;

    .select-file-button {
        position: relative;
        width: 10rem;
        height: 10rem;
        background-color: rgba(89, 96, 160, 1);
        cursor: pointer;
        border-radius: 100%;
        border: 0px;
        transition-duration: 0.8s;
        transition-property: transform;
    }

    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .select-file-button.animate {
        animation: rotating 1s linear infinite;
    }

    .select-file-button-circle-border-hover {
        position: absolute;
        width: 11rem;
        height: 11rem;
        left: -0.5rem;
        top: -1.5rem;
        background-color: rgba(89, 96, 160, 1);
        border-radius: 100%;
    }

    .select-file-button-circle-border.hovered {
        background-color: rgb(108, 116, 194);
    }

    .select-file-button-circle-border-hover {
        position: absolute;
        width: 11rem;
        height: 11rem;
        left: -0.5rem;
        top: -1.5rem;
        background-color: white;
        border-radius: 100%;
    }
    .select-file-button-text {
        position: relative;
        text-align: center;
        translate: 0 230%;
        background-color: #5960A0;
        color: white;
        padding: 0.7rem .5rem;
        border-radius: 12px;

        &_none {
            display: none;
        }
    }

    .icon {
        position: absolute;
        cursor: pointer;
        font-size: 4rem;
        color: rgba(89, 96, 160, 1);
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

    .icon.hovered {
        color: rgb(108, 116, 194);
    }

    .file-input {
        display: none;
    }
}
