
.container {
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-self: center;
    padding-bottom: 4rem;
}

.donateContainer {
    margin: 0 auto;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}