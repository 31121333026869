.file-view {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .imgage-container {
    max-height: 70vh;
    max-width: 90vw;
    overflow: auto;
    /*padding: 0 1rem;*/

    .image-view {
      max-height: 99%;
      max-width: 99%;
    }
  }

  .image-detail {
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*max-height: 20vh;*/
    overflow: auto;
    /*flex-basis: 25%;*/
    min-height: 25%;

    &-title {
      font-size: 1.125rem;
      font-weight: 500;
    }

    &-buttons {
      display: flex;
      gap: 0.625rem;;
    }

    &-links {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 0.625rem;;
      padding: 0 1rem;
      width: 80vmin;
      min-width: 55vw;

      &-line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-titles {
          margin: 0;
          line-height: 1.5rem;
          min-width: 5rem;
        }

        &-values {
          flex-grow: 1;
          display: flex;
          gap: 0.5rem;

          &-input {
            font-size: 0.875rem;
            flex-grow: 1;
            background-color: lightgray;
            border: none;
            padding: 0.25rem;
            outline: none;
            width: 0;
          }
        }
      }
    }
  }

  .image-error {
    width: 90vw;
    padding: 1rem;
    text-align: center;
    margin: 1rem;
    border-radius: 8px;
    font-size: 1.2rem;
    background-color: #fdefef;
  }
}