.group-nav {
    margin-right: auto;
    margin-left: 30px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
}

.navbar-item {
    list-style-type: none;
    color: #000000;
    font-size: 18px;
}

.navbar-mob {
    display: none;
}

@media only screen and (max-device-width: 640px) {

    .group-nav {
        margin-right: 0;
        margin-left: 0;
    }

    .navbar {
        display: none;
    }

    .navbar-mob {
        display: flex;
    }

    .group-nav {
        order: -1;
    }

    #menu__toggle {
        opacity: 0;
    }

    #menu__toggle:checked+.menu__btn>span {
        transform: rotate(45deg);
    }

    #menu__toggle:checked+.menu__btn>span::before {
        top: 0;
        transform: rotate(0deg);
    }

    #menu__toggle:checked+.menu__btn>span::after {
        top: 0;
        transform: rotate(90deg);
    }

    #menu__toggle:checked~.menu__box {
        left: 0 !important;
    }

    .menu__btn {
        position: relative;
        top: 0px;
        left: 0px;    
        width: 26px;
        height: 26px;
        cursor: pointer;
        z-index: 4;
        transform: translate(0);
    }

    .menu__btn>span,
    .menu__btn>span::before,
    .menu__btn>span::after {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #000000;
        transition-duration: .25s;
    }

    .menu__btn>span::before {
        content: '';
        top: -8px;
    }

    .menu__btn>span::after {
        content: '';
        top: 8px;
    }

    .menu__box {
        display: flex;
        position: absolute;
        top: 0;
        left: -100%;
        width: 60%;
        height: 100%;
        margin: 0;
        padding: 80px 20px 10px 32px;
        list-style: none;
        background-color: #FFFFFF;
        transition-duration: .25s;
        z-index: 3;
        flex-direction: column;
        gap: 28px;
    }

    .menu__item {
        display: block;
        padding: 12px 24px;
        color: #000000;
        font-size: 20px;
        font-weight: 600;
        text-decoration: none;
        transition-duration: .25s;
    }
}