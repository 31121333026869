.walletsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.walletText {
    font-size: 16px;
    margin: 0;
    padding: 0;
}
 
.walletsContainer > div,
.wallet {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    justify-content: center;

    p {
        margin: 0;
    }

    img {
        width: 20px;
        height: 20px;
        margin: 0;
    }
}

a {
    text-decoration: none;
}

.donateBtn {
    color: #FFF;
    border-radius: 12px;
    background-color: #5960A0;
    border: 1px solid #5960A0;
    margin: 0;
    align-items: center;
    padding: 0.7rem 2rem;
}

.qrWrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.hidden {
    display: none !important;
}

.qr {
    background-color: #fff;
    padding: 30px;
    border-radius: 40px;
}


@media (max-width: 700px) {
    .walletsContainer {
        justify-content: center;
    }

    .walletText {
        font-size: 12px;
        text-align: center;
    }
}
