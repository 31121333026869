.toast {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 6px;
  font-weight: 500;
  color: white;
  background-color: #409eff;
  transition: all 0.5s;
  opacity: 0;

  &.show {
    opacity: 1;
  }
}