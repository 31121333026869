a {
  text-decoration: none;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding: 0 5%;
}

.login-wrapper {
  display: flex;
  gap: 40px;
  align-items: center;
}

.login {
  font-size: 18px;
  display: none;
}