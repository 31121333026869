.logo {
    margin: 0;
    padding: 0;
    color: #000000;
}


@media only screen and (max-device-width: 640px) { 

    .logo {
        width: 160px;
    }

}