.login-modal-wrapper {
    position: absolute;
    // display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 10;
}

.login-modal {
    width: 400px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 19px;
    background-color: #FFFFFF;
    padding: 2rem;
    border: 1px solid #858585;
}

.login-modal-title {
    font-size: 24px;
    font-weight: 800;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    label {
        font-size: 1.125rem;
        line-height: 100%;
    }

    input {
        background-color: #FFFFFF;
        padding: 10px;
        border: 1px solid #000000;
        border-radius: 8px;
        width: 100%;
    }
}

.login-btn {
    background-color: #111111;
    color: #FFFFFF;
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px;
    border-radius: 8px;
    outline: none;
    box-shadow: none;
    border: none;
}

.login-divider {
    font-size: 1.125rem;
    color: #c4c4c4;
    position: relative;
    width: 100%;
    text-align: center;
}

.login-divider::before,
.login-divider:after {
    display: block;
    content: '';
    height: 1px;
    width: 145px;
    background-color: #c4c4c4;
    top: 50%;
    position: absolute;
}

.login-divider::before {
    left: 0;
}

.login-divider:after {
    right: 0;
}

.socials {
    display: flex;
    gap: 40px;
}

.social-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 33px;
    border: 1px solid #000000;
    border-radius: 8px;

    img {
        width: 18px;
    }
}
