.page-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0;
    max-width: 800px;
}

.paragraph {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.page-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0;
    max-width: 800px;
}

.paragraph {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.paragraph-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        width: 45%;

        p {
            font-size: 1rem;
            text-align: center;
        }
    }
}