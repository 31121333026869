.file-upload-drop-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .file-upload-sub-title {
    white-space: pre-line;
    font-size: 20px;
    text-align: center;
  }

  .file-upload-title {
    font-size: 50px;
    font-weight: 800;
    text-align: center;
    line-height: 130%;
  }
}

@media only screen and (max-device-width: 640px) {

  .file-upload-drop-zone .file-upload-title  {
    font-size: 30px;
  }

  .file-upload-drop-zone .file-upload-sub-title {
    font-size: 16px;
  }

  .file-upload-drop-zone {
    padding-top: 20px;
  }
}